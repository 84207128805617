@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100.7, "YTLC" 500;
}

.container {
  min-width: 1140px;
  max-width: 1140px;
  margin: 0 auto;
}

@media (max-width: 1250px) {
  .container {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 0 5rem;
  }
}

@media (max-width: 768px) {
  .container {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 0 2rem;
  }
}

@media (max-width: 1024px) {
  .contact-info-box h3 {
    font-size: 21px;
  }

  .single-funfact h3 {
    font-size: 29px;
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.crake-nav {
  background-image: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 15px;
  width: 100%;
  z-index: 666;
  height: auto;
}

.crake-nav.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  background-color: #13c4a1 !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.main-banner {
  background: #13c4a1;
  position: relative;
  z-index: 999;
}

.page-title-banner h2 {
  margin: 0;
  color: #ffffff;
  font-size: 35px;
  font-weight: 700;
  text-align: center;
}

.about-content .section-title {
  margin-bottom: 15px;
  text-align: left;
  margin-left: initial;
  margin-right: initial;
}

.about-content .section-title .bar {
  margin-left: 0;
  margin-right: 0;
}

.about-content .section-title p {
  margin-left: 0;
  margin-right: 0;
}

.section-title h2 {
  font-size: 29px;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar-nav li {
  position: relative;
  display: block;

  color: #fff;
}

.navbar-nav li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  visibility: hidden;
  transition: 0.4s;
}

.navbar-nav li a {
  color: #ffffff !important;
  padding: 0 0 !important;
  margin: 0 17px;
  position: relative;
  font-weight: 600;
  font-size: 17px;
}

.navbar-nav li a:hover::before {
  left: -17px;
  opacity: 1;
  visibility: visible;
}

.others-option .navbar-nav li .side-menu {
  cursor: pointer;
  width: 32px;
}

.others-option .navbar-nav li .side-menu .bar-1 {
  position: relative;
  width: 8px;
  height: 8px;
  background: #ffffff;
  display: block;
  border-radius: 50%;
  margin-bottom: 2px;
  transition: all 0.4s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-1::before,
.others-option .navbar-nav li .side-menu .bar-1::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-1::after {
  right: -24px !important;
}

.others-option .navbar-nav li .side-menu .bar-2 {
  position: relative;
  width: 8px;
  height: 8px;
  margin-bottom: 2px;
  background: #ffffff;
  display: block;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-2::before,
.others-option .navbar-nav li .side-menu .bar-2::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-2::after {
  right: -24px;
}

.others-option .navbar-nav li .side-menu .bar-3 {
  position: relative;
  width: 8px;
  height: 8px;
  background: #ffffff;
  margin-bottom: 2px;
  display: block;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-3::before,
.others-option .navbar-nav li .side-menu .bar-3::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.6s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-3::after {
  right: -24px !important;
  transition: all 0.8s ease-in-out;
}

.others-option .navbar-nav li .side-menu .bar-3::before,
.others-option .navbar-nav li .side-menu .bar-3::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.6s ease-in-out;
}

.shape-1 {
  left: 20px;
  bottom: 10px;
  height: 150px;
  width: 150px;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  background-color: #edf5ff;
}

.shape-2 {
  position: absolute;
  top: 85px;
  z-index: -1;
  right: 30px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape-3 {
  position: absolute;
  bottom: 85px;
  z-index: -1;
  right: 30px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.btn-banner {
  order: none;
  padding: 13px 31px;
  border-radius: 5px;
  text-transform: uppercase;
  position: relative;
  box-shadow: 0 13px 27px 0 rgba(163, 48, 53, 0.25);
  z-index: 1;
  transition: 0.9s;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background-color: #ff8a73;
}

.btn-banner::after,
.btn-banner::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  transition: 0.4s;
  background: #13c4a1;
  z-index: -1;
  border-radius: 5px;
}

.btn-banner::before {
  right: 0;
}

.btn-banner:hover::before {
  background: #ffffff;
}

.btn-banner:hover::after {
  background: #ffffff;
}

.btn-banner:hover {
  background: #ffffff !important;
  color: #ff8a73 !important;
}

.btn-banner::after {
  left: 0;
}

.btn-banner:hover::after,
.btn-banner:hover::before {
  width: 50%;
}

.p-banner {
  padding-top: 100px;
  padding-bottom: 160px;
}

.fixed-header {
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-banner {
    height: 100%;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
}

.sidebar-modal .modal-header .close:hover {
  background: #ff8a73;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.e {
  transition: all 0.3s ease-in-out;
}

.fade {
  transition: opacity 0.15s linear;
}

.sidebar-modal {
  position: relative;
}

.sidebar-modal .modal.right.fade .modal-dialog {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.sidebar-modal .modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 480px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}

.sidebar-modal .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.sidebar-modal .modal-header {
  display: inline;
  padding: 0;
  border: none;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.sidebar-modal .modal.right .modal-body {
  padding: 30px;
}

.sidebar-modal .modal-header .close {
  height: 60px;
  width: 60px;
  background: #13c4a1;
  font-size: 30px;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  opacity: 1;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.sidebar-modal .modal-header .close {
  height: 60px;
  width: 60px;
  background: #13c4a1;
  font-size: 30px;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  opacity: 1;
}

.sidebar-modal .modal-header h2 {
  background: linear-gradient(142deg, #3ec9cf 0%, #2d136a 100%);
  padding: 30px 25px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.sidebar-modal .modal.right .modal-body .sidebar-modal-widget {
  margin-bottom: 35px;
}

.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .title {
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
  font-size: 23px;
  font-weight: 700;
}

.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .title::before {
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 70px;
  height: 2px;
  border-radius: 5px;
  background: #13c4a1;
  content: "";
}

.sidebar-modal .modal.right .modal-body .sidebar-modal-widget .title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  height: 2px;
  width: 5px;
  background: #ffffff;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

.sidebar-modal
  .modal.right
  .modal-body
  .sidebar-modal-widget
  ul.contact-info
  li {
  position: relative;
  font-weight: 700;
  padding-left: 65px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.sidebar-modal
  .modal.right
  .modal-body
  .sidebar-modal-widget
  ul.contact-info
  li
  span {
  display: block;
  font-weight: 400;
  color: #818992;
  margin-top: 5px;
  text-transform: initial;
}

.sidebar-modal
  .modal.right
  .modal-body
  .sidebar-modal-widget
  ul.contact-info
  li
  i {
  position: absolute;
  left: 0;
  top: 0;
  background: #f4f4f4 none repeat scroll 0 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #13c4a1;
  height: 50px;
  text-align: center;
  width: 50px;
  line-height: 50px;
  font-size: 25px;
  transition: 0.4s;
}

.icofont-google-map:before {
  content: "\ed42";
}

.icofont-email:before {
  content: "\ef12";
}

.icofont-phone:before {
  content: "\efbb";
}

.sidebar-modal
  .modal.right
  .modal-body
  .sidebar-modal-widget
  ul.social-list
  li {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 7px;
}

.icofont-close:before {
  content: "\eee4";
}

.sidebar-modal
  .modal.right
  .modal-body
  .sidebar-modal-widget
  ul.social-list
  li
  a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
  border-radius: 50%;
  color: #818992;
  border: 1px solid #818992;
}

@keyframes MOVE-BG {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(85px);
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  /* .navbar-nav li {
        border: none;
        padding: 10px 20px;
        color: #fff;
    } */
  .navbar-toggler {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .navbar-light .navbar-toggler {
    color: #ffffff;
    border-color: #ffffff;
  }

  .navbar-collapse {
    margin-top: 15px;
    background: #ffffff;
  }

  .navbar-nav {
    padding: 10px 0 !important;
  }

  .navbar-nav li a {
    color: #000 !important;
    display: block;
    padding: 10px 0 !important;
  }

  .mobile-hidden {
    display: none;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.fade {
  transition: opacity 0.15s linear;
}

.modal-backdrop.show {
  opacity: 0.5;
}

section {
  padding: 100px 0px;
}

.section-title {
  text-align: center;
  max-width: 720px;
  margin-bottom: 70px;
  margin-top: -6px;
  margin-left: auto;
  margin-right: auto;
}

.section-title .bar {
  height: 4px;
  width: 85px;
  background: #13c4a1;
  margin: 20px auto 25px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.section-title .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

p {
  font-size: 16px;
  color: #818992;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.section-title h2 {
  margin-bottom: 0;
  font-size: 35px;
  font-weight: 700;
}

.section-title p {
  max-width: 650px;
  margin: 0 auto;
}

.single-features {
  text-align: center;
  transition: 0.4s;
  margin-bottom: 40px;
  cursor: pointer;
}

.single-features h3 {
  margin: 25px 0 15px;
  font-size: 23px;
  font-weight: 700;
}

.single-features .icon {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #edf5ff;
  border-radius: 50%;
  font-size: 30px;
  color: #13c4a1;
  transition: 0.4s;
}

.features-area {
  position: relative;
}

.shape7 {
  position: absolute;
  top: 20%;
  z-index: -1;
  left: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  opacity: 0.4;
}

.shape8 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 15%;
}

.shape9 {
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: -1;
  -webkit-animation: animationFramesOne 15s infinite linear;
  animation: animationFramesOne 15s infinite linear;
}

.shape6 {
  position: absolute;
  top: 30%;
  z-index: -1;
  left: 30px;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
  opacity: 0.6;
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape10 {
  position: absolute;
  right: 5%;
  top: 8%;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape11 {
  position: absolute;
  left: 3%;
  bottom: 3%;
  z-index: -1;
}

.features-inner-area {
  margin-top: 60px;
}

.single-features:hover {
  transform: translateY(-10px);
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.features-inner-content {
  max-width: 540px;
}

.features-inner-area .features-inner-content .features-item {
  margin-bottom: 35px;
  position: relative;
  padding-left: 90px;
  transition: 0.4s;
}

.features-inner-area .features-inner-content .features-item {
  margin-bottom: 35px;
  position: relative;
  padding-left: 90px;
  transition: 0.4s;
}

.features-inner-area .features-inner-content .features-item .icon {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  line-height: 75px;
  background: #ffffff;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  color: #13c4a1;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1) !important;
  transition: 0.4s;
}

.features-inner-area .features-inner-content .features-item h3 {
  margin: 0 0 15px;
  font-size: 23px;
  font-weight: 700;
}

.features-inner-area .features-inner-content .features-item:hover {
  transform: translateY(-10px);
}

.bg-gray {
  background-color: #edf5ff;
}

.saas-tools {
  position: relative;
  z-index: 1;
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.app-funfacts-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #13c4a1;
  opacity: 0.87;
}

.app-funfacts-area {
  position: relative;
  z-index: 1;
  background-image: url(./../assets//img/cta-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding-bottom: 70px;
}

.app-funfacts-area .single-funfact {
  border: none;
  padding: 0;
  margin-bottom: 30px;
}

.single-funfact {
  position: relative;
  text-align: center;
  border: 1px solid #edf5ff;
  transition: 0.4s;
  border-radius: 5px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

.single-funfact .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background: #edf5ff;
  border-radius: 50%;
  font-size: 25px;
  color: #13c4a1;
  transition: 0.4s;
}

.app-funfacts-area .single-funfact h3 {
  color: #ffffff;
}

.single-funfact h3 {
  margin: 25px 0 8px;
  font-size: 35px;
  font-weight: 700;
}

.app-funfacts-area .single-funfact p {
  color: #ffffff;
  font-size: 17px;
}

.single-funfact:hover {
  transform: translate(0, -10px);
}

.single-team-member {
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  margin-bottom: 30px;
  background: #ffffff;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-area.bg-image {
  background-image: url(./../assets/img/footer.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.copyright-area {
  padding: 30px 0;

  border-top: 1px solid #eeeeee;
}

.copyright-area ul li a {
  color: #818992;
}

.copyright-area ul li:nth-child(2):before {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  background: #ff8a73;
  width: 2px;
  height: 12px;
}

.copyright-area ul li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
}

.contact-info-box {
  height: 100%;
  text-align: center;
  transition: 0.4s;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 30px;
}

.contact-info-box .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #edf5ff;
  border-radius: 50%;
  font-size: 30px;
  color: #13c4a1;
  transition: 0.4s;
}

.contact-info-box h3 {
  margin: 25px 0 15px;
  font-size: 23px;
  font-weight: 700;
}

.contact-info-box:hover {
  transform: translateY(-10px);
}

.contact-info-box p a {
  color: #818992;
}

.form-control {
  height: 50px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  transition: 0.4s;
  color: #818992;
  background: #ffffff;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group {
  margin-bottom: 15px;
}

#contactForm .btn {
  margin-top: 10px;
}

.btn {
  border: none;
  padding: 13px 31px;
  border-radius: 5px;
  text-transform: uppercase;
  position: relative;
  box-shadow: 0 13px 27px 0 rgba(163, 48, 53, 0.25);
  z-index: 1;
  transition: 0.9s;
  font-weight: 700;
  font-size: 16px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #ffffff;
  background: #13c4a1;
  transform: translateY(-5px);
}

.btn-primary {
  color: #ffffff;
  background-color: #ff8a73;
}

.btn::after,
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  transition: 0.4s;
  background: #13c4a1;
  z-index: -1;
  border-radius: 5px;
}

.btn:hover::after,
.btn:hover::before {
  width: 50%;
}

.btn::before {
  right: 0;
}

.btn::after {
  left: 0;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.paddingnone {
  /* max-width: 100vw !important; */
  padding: 0 !important;
}

.paddingnonev4 {
  max-width: 100vw !important;
  padding: 0 !important;
}

.text_banner_aboutus {
  /* position: absolute;
     top: 50%;
     left: 50%; */
  color: #13c4a1 !important;
  transform: translate(0%, 40px);
}
.paddingnonev2 {
  background-color: white !important;
  padding: 0px !important;
}

.paddingnonev3 {
  /* background-color: white !important; */
  padding: 0px 0px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SwiperSlides {
  overflow: hidden;
  width: 100%;
  height: 600px;
}

.textcontactus {
  position: absolute;
  color: #13c4a1 !important;
  left: 50%;
  transform: translate(-50%, 50px);
}
